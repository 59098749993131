<template>
    <bs-popup id="master-unit-popup" @on-popup-closed="onPopupClosed" :auto-show="true" :is-medium="true" ref="form">
        <template v-slot:title>Master Unit</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="code" class="form-label">Code</label>
                <bs-plain-text v-model="model.code" />
            </div>

            <div class="mb-3">
                <label for="description" class="form-label">Description</label>
                <bs-plain-text v-model="model.description" />
            </div>

            <div class="mb-3">
                <label for="isDefault" class="form-label">Is Default Unit</label>
                <br>
                <bs-checkbox :checked="model.isDefault" disabled="disabled"/>
            </div>

            <div>
                <div class="row justify-content-between">
                    <div class="col-md-6">
                        <div class="d-grid gap-3 d-md-block">
                            <h5>Unit Conversion</h5>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="pagination-right">
                            <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow"
                                           @toPage="toPage"/>
                        </div>
                    </div>
                </div>
                <div class="">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>
                                <bs-ordering property="FromUnitCode" @reorder="reorder">From Unit</bs-ordering>
                            </th>
                            <th>
                                <bs-ordering property="ToUnitCode" @reorder="reorder">To Unit</bs-ordering>
                            </th>
                            <th>
                                <bs-ordering property="coefisien" @reorder="reorder">Coefficient</bs-ordering>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="unitConversionData != null && unitConversionData.length > 0">
                            <tr v-for="item in unitConversionData" :key="item.id">
                                <td>
                                    {{ item.fromUnitCode }}
                                </td>

                                <td>
                                    {{ item.toUnitCode }}
                                </td>
                                <td>
                                    <bs-plain-text v-model="item.coefisien" />
                                    <bs-input-error :errors="errorMessages" keyError="coefisien" />
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>



        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="editData"><i class="bi bi-pencil-square"></i> Edit</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import UnitService from "@/services/unit-service.js";
import UnitConversionService from "@/services/unit-conversion-service.js";
import BsCheckbox from "@/plugins/components/BsCheckbox.vue";

export default {
    components: {BsCheckbox},
    data: function() {
        return {
            filter: {
                ToUnitIdEquals: '',
                FromUnitCodeEquals: '',
                ToUnitCodeEquals: '',
                page: 1,
                perPage: 10,
                orderByProperty: 'id',
                orderType: 0
            },

            id: null,
            model: UnitService.default(),
            unitConversionModels: UnitConversionService.default(),
            errorMessages: null,

            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            unitConversionData: Array(),
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.id = id;
        this.loadData();
        this.loadUnitConversion();
    },
    methods: {
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            await this.loadUnitConversion();
        },
        async reorder(property, orderType) {
            this.filter.orderByProperty = property;
            this.filter.orderType = orderType;
            await this.loadUnitConversion();
        },
        async search() {
            this.filter.page = 1;
            await this.loadUnitConversion();
        },
        async loadData() {
            let response = await UnitService.getObject(this.id);
            this.model = response.data;
            if (response.status) 
            {
                this.errorMessages = null;
            }
            else 
            {
                this.errorMessages = response.errorMessages;
            }
        },
        async loadUnitConversion() {
            this.filter.ToUnitIdEquals = this.id;
            const unitConResult = await UnitConversionService.getList(this.filter);
            if (unitConResult.status) {
                this.errorMessages = null;
            } else {
                this.errorMessages = unitConResult.errorMessages;
            }
            let unitConResultData = unitConResult.data;

            const unitResult = await UnitService.getList(this.filter);
            if (unitResult.status) {
                this.errorMessages = null;
            } else {
                this.errorMessages = unitResult.errorMessages;
            }
            let unitResultData = unitResult.data;

            let data = unitConResultData;
            for (let i = 0; i < unitResultData.data.length; i++) {
                let isFound = unitConResultData.data.find(x => x.fromUnitId === unitResultData.data[i].id);
                if (!isFound) {
                    data.data.push({
                        fromUnitId: unitResultData.data[i].id,
                        fromUnitCode: unitResultData.data[i].code,
                        toUnitId: this.model.id,
                        toUnitCode: this.model.code,
                        coefisien: unitResultData.data[i].id === this.model.id ? 1 : null
                    })
                }
            }

            this.pageNumber = data.pageNumber;
            this.perPage = data.perPage;
            this.totalPage = data.totalPage;
            this.totalRow = data.totalRow;

            this.unitConversionData = data.data;
            console.log(this.unitConversionData)
        },
        onPopupClosed() {
            this.$router.push('/master-unit');
        },
        close() {
            this.$refs.form.closePopup();
        },
        editData() 
        {
            this.$refs.form.closePopup();
            this.$router.push(`/master-unit/edit/${this.id}`);
        }
    }
}
</script>